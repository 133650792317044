var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personalContent" }, [
    _c(
      "div",
      { staticClass: "personalHead" },
      [
        _c("i-icon", { attrs: { name: "icon-user-circular", size: 56 } }),
        _c("div", { staticClass: "personalName" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.userMessage.dspName)),
          ]),
          _c("div", { staticClass: "phone" }, [
            _vm._v(_vm._s(_vm.userMessage.phoneNumber)),
          ]),
        ]),
        _c("img", {
          staticClass: "personalVersionBg",
          attrs: { src: require("../assets/img/personal.png") },
        }),
        _c("span", { staticClass: "personalVersion" }, [
          _vm._v("当前版本" + _vm._s(_vm.getVersion())),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "personalField" },
      [
        _vm.isFirst ? _c("i-skeleton", { attrs: { row: 10 } }) : _vm._e(),
        _vm._l(_vm.fieldList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "row-item",
              style: index == 1 ? "border-bottom: 5px solid #fafafa" : "",
              on: {
                click: function ($event) {
                  return _vm.fieldClick(item)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("i-icon", {
                    attrs: { name: item.icon, color: item.color },
                  }),
                  _vm._v(" " + _vm._s(item.title) + " "),
                ],
                1
              ),
              _c("div", { staticClass: "right" }, [_vm._v(_vm._s(item.right))]),
            ]
          )
        }),
        _vm.getCookie("tenant") !== "msd"
          ? _c(
              "div",
              { staticClass: "loginOutRow" },
              [
                _c(
                  "i-button",
                  {
                    attrs: { block: "", size: "normal" },
                    on: {
                      click: function ($event) {
                        return _vm.openMsg("logoutAsync")
                      },
                    },
                  },
                  [_vm._v(" 退出登录 ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }